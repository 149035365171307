import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContentLoader from './components/ContentLoader';
import NotFound from './components/NotFound';
import NoCrypto from './components/NoCrypto';

function App() {
  const isCryptoAvailable = typeof crypto !== 'undefined' && crypto.subtle;

  return (
    <Router>
      <Routes>
        {/* Verifica si crypto.subtle está definido */}
        {isCryptoAvailable ? (
          <>
            {/* Ruta para película */}
            <Route path="/embed/:tmdb_id" element={<ContentLoader />} />

            {/* Ruta para episodio */}
            <Route path="/embed/:tmdb_id/:season/:episode" element={<ContentLoader />} />
          </>
        ) : (
          <Route path="*" element={<NoCrypto />} />
        )}

        {/* Ruta 404 para cualquier otra URL */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;


import React from 'react';

function NoCrypto() {
  return (
    <div>
      No crypto api dependence found on browser.
    </div>
  );
}

export default NoCrypto;
import { useMemo } from 'react';

export function useExternalSources(externalSources, video_data) {
  return useMemo(() => {
    if (!video_data) return [];

    // Mapear los external sources y reemplazar las variables en las URLs
    return externalSources.map((source) => {
      if (
        (video_data.type === 'movie' && source.type !== 'movie') ||
        (video_data.type === 'serie' && source.type !== 'serie')
      ) {
        return null;
      }

      let url = source.url
        .replace('TMDB_ID', video_data.tmdb_id || '')
        .replace('POST_IMDB_ID', video_data.post_imdb_id || '');

      if (video_data.type === 'serie') {
        if (!video_data.episode_imdb_id) {
          return null; // Si no hay episodio válido, no incluir la fuente
        }

        // Ajustar el episode_number si la fuente tiene id 'embedsito' y el número es menor a 10
        let episodeNumber = video_data.episode_number;
        if (source.service_id === 'embedsito' && episodeNumber < 10) {
          episodeNumber = `0${episodeNumber}`;
        }

        url = url
          .replace('EPISODE_IMDB_ID', video_data.episode_imdb_id || '')
          .replace('EPISODE_NUMBER', episodeNumber || '')
          .replace('SEASON_NUMBER', video_data.season_number || '');
      }

      return {
        ...source,
        service:{
            id: source.service_id,
            name: source.service_id
        },
        icon: null,
        source: 'embed',
        embed: url,
      };
    }).filter((source) => source !== null);
  }, [externalSources, video_data]);
}
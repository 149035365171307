export const external_sources = [
    { service_id: "embedsito", url: "https://embedsito.net/video/POST_IMDB_ID", type: "movie", languages: ["lat", "es-es"] },
    { service_id: "embedsito", url: "https://embedsito.net/video/POST_IMDB_ID-SEASON_NUMBERxEPISODE_NUMBER", type: "serie", languages: ["lat", "es-es"] },
    { service_id: "vhdl", url: "https://verhdlink.cam/movie/POST_IMDB_ID", type: "movie", languages: ["lat", "es-es"] },
    { service_id: "multiembeddirect", url: "https://multiembed.mov/directstream.php?video_id=TMDB_ID&tmdb=1", type: "movie", languages: ["en"] },
    { service_id: "multiembeddirect", url: "https://multiembed.mov/directstream.php?video_id=TMDB_ID&tmdb=1&s=SEASON_NUMBER&e=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "multiembed", url: "https://multiembed.mov/?video_id=TMDB_ID&tmdb=1", type: "movie", languages: ["en"] },
    { service_id: "multiembed", url: "https://multiembed.mov/?video_id=TMDB_ID&tmdb=1&s=SEASON_NUMBER&e=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "vidsrc", url: "https://vidsrc.xyz/embed/movie?tmdb=TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "vidsrc", url: "https://vidsrc.xyz/embed/tv?tmdb=TMDB_ID&season=SEASON_NUMBER&episode=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "hnembed", url: "https://hnembed.cc/embed/movie/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "hnembed", url: "https://hnembed.cc/embed/tv/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "moviesapi", url: "https://moviesapi.club/movie/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "moviesapi", url: "https://moviesapi.club/tv/TMDB_ID-SEASON_NUMBER-EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "2embed", url: "https://2embed.cc/embed/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "2embed", url: "https://2embed.cc/embedtv/TMDB_ID&s=SEASON_NUMBER&e=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "123embed", url: "https://play2.123embed.net/movie/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "123embed", url: "https://play2.123embed.net/tv/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "smashy", url: "https://player.smashy.stream/movie/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "smashy", url: "https://player.smashy.stream/tv/TMDB_ID?s=SEASON_NUMBER&e=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "filmku", url: "https://filmku.stream/embed/movie?tmdb=TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "filmku", url: "https://filmku.stream/embed/series?tmdb=TMDB_ID&sea=SEASON_NUMBER&epi=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "anyembed", url: "https://anyembed.xyz/movie/TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "anyembed", url: "https://anyembed.xyz/tv/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "filmclub", url: "https://vip.filmclub.tv/embed/movie?tmdb=TMDB_ID", type: "movie", languages: ["en"] },
    { service_id: "filmclub", url: "https://vip.filmclub.tv/embed/series?tmdb=TMDB_ID&sea=SEASON_NUMBER&epi=EPISODE_NUMBER", type: "serie", languages: ["en"] },
    { service_id: "embedder", url: "https://embedder.net/e/movie?tmdb=TMDB_ID", type: "movie", languages: ["pt"] },
    { service_id: "embedder", url: "https://embedder.net/e/series?tmdb=TMDB_ID&sea=SEASON_NUMBER&epi=EPISODE_NUMBER", type: "serie", languages: ["pt"] },
    { service_id: "warez", url: "https://embed.warezcdn.com/filme/POST_IMDB_ID", type: "movie", languages: ["pt"] },
    { service_id: "warez", url: "https://embed.warezcdn.link/serie/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER", type: "serie", languages: ["pt"] },
    { service_id: "superflix", url: "https://superflixapi.dev/filme/POST_IMDB_ID", type: "movie", languages: ["pt"] },
    { service_id: "superflix", url: "https://superflixapi.dev/serie/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER#noEpList", type: "serie", languages: ["pt"] },
    { service_id: "eplayer", url: "https://embed.embedplayer.site/POST_IMDB_ID", type: "movie", languages: ["pt"] },
    { service_id: "eplayer", url: "https://embed.embedplayer.site/tv/TMDB_ID/SEASON_NUMBER/EPISODE_NUMBER/dub", type: "serie", languages: ["pt"] },
    { service_id: "pumba", url: "https://lapumba.xyz/e/TMDB_ID-dub", type: "movie", languages: ["pt"] },
    { service_id: "pumba", url: "https://lapumba.xyz/e/tvTMDB_ID-dub/SEASON_NUMBER-EPISODE_NUMBER", type: "serie", languages: ["pt"] },
    { service_id: "mplayer", url: "https://embed.embedyplayer.xyz/embed/movie?tmdb=TMDB_ID", type: "movie", languages: ["pt"] },
    { service_id: "mplayer", url: "https://embed.embedyplayer.xyz/embed/series?tmdb=TMDB_ID&sea=SEASON_NUMBER&epi=EPISODE_NUMBER", type: "serie", languages: ["pt"] },
];
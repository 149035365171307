import React from 'react';
import Mirror from './Mirror';
import Player from './Player';

function Embed({ option, title, cover, ads }) {
    let url_embed = '';

    if (option.source === 'code' && option.service?.embed_path && option.service.id !== 1 && option.service.id !== 8) {
        url_embed = option.service.service_url + option.service.embed_path.replace('FILE_CODE', option.embed);
    } else if (option.source === 'embed' || option.source === 'mp4') {
        url_embed = option.embed;
    }
   
    if (option.service.id === 1) {
        return (
            <Mirror share_id={option.embed} title={title} quality={option.quality} cover={cover} ads={ads} />
        );
    } else if(option.service.id === 8 || option.source === 'mp4'){
        return (
            <Player title={title} sources={[{src: option.embed,type: 'video/mp4',size: option.quality??1080}]} poster={cover} tracks={[]} ads={ads}/>
        );
    }else{
        return (
            <iframe
                src={url_embed}
                style={{ width: "100%", height: "99vh", border: "none" }}
                title={title}
            />
        )
    }


}

export default Embed;
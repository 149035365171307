import React from 'react';

import '../assets/css/play.css';

function Play({ config_data, video_data, set_play }) {
  // Filtrar los anuncios que tengan el tipo 'pop_under'
  const popUnderAds = config_data.ads.filter(ad => ad.type === 'pop_under');

  // Seleccionar uno de ellos aleatoriamente
  const ad_link = popUnderAds.length > 0 ? popUnderAds[Math.floor(Math.random() * popUnderAds.length)].link : null;

  const handleClick = (e) => {

    if (ad_link) {
      const popunder = window.open(ad_link, '_blank', 'width=800,height=600');

      if (popunder) {
        e.preventDefault();
        // Intenta mover la ventana emergente a una posición detrás de la ventana principal
        popunder.blur();
        popunder.opener.window.focus();

        // Forzar la ventana principal a traerla al frente (algunas veces necesario)
        setTimeout(() => {
          window.focus();
        }, 100);

        // Cambiar el estado de play a true
        set_play(true);

      } else {
        console.log('La ventana emergente fue bloqueada.');
      }
    } else {
      e.preventDefault();
      // Cambiar el estado de play a true
      set_play(true);
    }
  };

  if (!video_data || !config_data) {
    return null; // Puedes mostrar un cargando o simplemente no renderizar nada mientras video_data es null
  }

  return (
    <div className="play-container">
      <a
        id="play-video"
        className="video-play-button"
        href={ad_link ? ad_link : '#'}
        target={ad_link ? '_blank' : undefined}
        onClick={handleClick}
      >
        <span></span>
      </a>
      <div
        id="video-overlay"
        className="video-overlay"
        style={{ backgroundImage: `url(${video_data.cover})` }}
      >
        <div className="video-overlay-header mt-1 ms-2">
          {
            video_data.season_number != null && video_data.episode_number != null
              ? `${video_data.title} S${video_data.season_number}E${video_data.episode_number}`
              : video_data.title
          }
        </div>
      </div>
    </div>
  );
}

export default Play;
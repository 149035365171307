import React, { useState } from 'react';
import Play from './Play';
import Embeds from './Embeds';

function Content({ config_data, video_data }) {
    const [play, setPlay] = useState(false);

    return (
        <>
            {!play ? (
                <Play config_data={config_data} video_data={video_data} set_play={setPlay} />
            ) : (
                <Embeds config_data={config_data} video_data={video_data}/>
            )}
        </>
    );
}

export default Content;
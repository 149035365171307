
export const decodeEncryptedData = async (encryptedData, keys) => {
    console.log(keys)
    const decoder = new TextDecoder();

    // Convertir el IV de hexadecimal a Uint8Array
    const iv = new Uint8Array(encryptedData.iv.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    for (let keyString of keys) {
        try {
            // Importar la clave
            const key = await deriveKey(keyString);

            // Decodificar los datos
            const decrypted = await crypto.subtle.decrypt(
                {
                    name: "AES-GCM",
                    iv: iv
                },
                key,
                base64ToArrayBuffer(encryptedData.ct)
            );

            return JSON.parse(decoder.decode(decrypted));
        } catch (error) {
            console.log(error);
        }
    }

};

// Función para convertir Base64 a ArrayBuffer
const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

// Deriva una clave de AES a partir de la cadena
async function deriveKey(keyString) {
    const encoder = new TextEncoder();
    const keyMaterial = await crypto.subtle.importKey(
        'raw',
        encoder.encode(keyString),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
    );

    return await crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: encoder.encode('salt'), // Puedes usar un salt más seguro
            iterations: 100000,
            hash: 'SHA-256'
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['decrypt']
    );
}


import { useState, useEffect } from 'react';

function useGetMirrorTempLink(share_id) {
  const [tempLink, setTempLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!share_id) return;

    const getMirrorDownloadLink = async () => {
      setLoading(true);
      try {
        const shareResponse = await fetch(`https://www.amazon.com/drive/v1/shares/${share_id}?resourceVersion=V2&ContentType=JSON&asset=ALL`);
        const shareData = await shareResponse.json();

        const nodeId = shareData.nodeInfo?.id;

        if (nodeId) {
          const nodeResponse = await fetch(`https://www.amazon.com/drive/v1/nodes/${nodeId}/children?resourceVersion=V2&ContentType=JSON&limit=200&sort=%5B%22kind+DESC%22%2C+%22modifiedDate+DESC%22%5D&asset=ALL&tempLink=true&shareId=${share_id}`);
          const nodeData = await nodeResponse.json();
          setTempLink(nodeData.data?.[0]?.tempLink);
        } else {
          console.log("Error al obtener el Node ID.");
          setError("Error al obtener el Node ID.");
        }
      } catch (err) {
        console.error('Error:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getMirrorDownloadLink();
  }, [share_id]);

  return { tempLink, loading, error };
}

export default useGetMirrorTempLink;
import React, { useState, useEffect } from 'react';

import CustomSelect from './CustomSelect';
import Report from './Report';
import Embed from './Embed';
import { useExternalSources } from '../hooks/useExternalSources';
import { external_sources } from '../constants/externalSources';
import { report_status } from '../constants';
import Download from './Download';

function Embeds({ config_data, video_data }) {
    const [languagesWithSources, setLanguagesWithSources] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const formattedExternalSources = useExternalSources(external_sources, video_data);

    // Función para organizar los sources por idioma
    const organizeSourcesByLanguage = () => {
        const updatedLanguages = config_data.languages.map((lang) => {
            const internalSources = video_data.sources
                .filter((source) => source.language === lang.language)
                .map((source) => {
                    const service = config_data.services.find((service) => service.id === source.service_id);
                    return {
                        ...source,
                        service,
                    };
                });

            // Agregar los external sources al final
            const externalSourcesForLang = formattedExternalSources.filter((source) =>
                source.languages.includes(lang.language)
            );

            const combinedSources = [...internalSources, ...externalSourcesForLang];

            return {
                ...lang,
                sources: combinedSources,
            };
        }).filter((lang) => lang.sources.length > 0); // Solo los idiomas que tienen videos

        setLanguagesWithSources(updatedLanguages);
    };

    // Llamada para organizar los sources al cargar el componente
    useEffect(() => {
        organizeSourcesByLanguage();
        //console.log(languagesWithSources)
    }, [config_data, video_data, formattedExternalSources]);

    // Cargar idioma seleccionado del localStorage o usar el idioma del usuario
    useEffect(() => {
        const userLang = navigator.language;
        const savedLanguage = localStorage.getItem('selectedLanguage');

        let defaultLanguage;

        if (savedLanguage) {
            defaultLanguage = savedLanguage;
        } else if (userLang === 'es-ES') {
            defaultLanguage = 'es-es';
        } else if (userLang.startsWith('es') && userLang !== 'es-ES') {
            defaultLanguage = 'lat';
        } else {
            defaultLanguage = languagesWithSources.find(lang => lang.language === userLang.split('-')[0])?.language || languagesWithSources[0]?.language;
        }

        if (defaultLanguage) {
            setSelectedLanguage(defaultLanguage);
        }
    }, [languagesWithSources]);

    // Seleccionar automáticamente la primera opción del idioma seleccionado
    useEffect(() => {
        if (selectedLanguage) {
            const language = languagesWithSources.find(lang => lang.language === selectedLanguage);
            if (language && language.sources.length > 0) {
                setSelectedOption(language.sources[0]);
            }
        }
    }, [selectedLanguage, languagesWithSources]);

    // Manejar cambio de idioma
    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', language);
    };

    // Manejar cambio de opción
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <div style={{ position: "absolute", top: "10px", width: "95%", zIndex: 20, display: "flex", alignItems: 'center', gap: "5px", justifyContent: 'space-between' }}>

                <div style={{ display: "flex", gap: "5px" }}>
                    {selectedLanguage && (
                        <CustomSelect
                            selectText={"LANG"}
                            options={languagesWithSources.map(lang => ({
                                value: lang.language,
                                text: lang.large_name,
                                icon: lang.icon
                            }))}
                            selectedOption={selectedLanguage}
                            handleOptionChange={(option) => handleLanguageChange(option.value)}
                            ads={[]}
                        />

                    )}
                    {selectedLanguage && selectedOption &&
                        (
                            <CustomSelect
                                selectText={"SERVERS"}
                                options={languagesWithSources
                                    .find((lang) => lang.language === selectedLanguage)
                                    ?.sources.map((source, index) => ({
                                        value: source,
                                        text: `${source.service.name} ${(source.quality === null || source.quality === '' || source.quality === undefined) ? '' : source.quality === 0 ? 'CAM' : `${source.quality}p`}`,
                                        icon: source.service.icon // si tienes un icono para el servicio
                                    }))
                                }
                                selectedOption={selectedOption}
                                handleOptionChange={(option) => handleOptionChange(option.value)}
                                ads={[]}
                            />
                        )
                    }
                </div>
                <div style={{ display: "flex", alignItems: 'center', gap: "5px" }}>
                    {selectedLanguage &&
                        (
                            <Download
                                options={languagesWithSources
                                    .find((lang) => lang.language === selectedLanguage)
                                    ?.sources
                                    .filter((source) => source.service.download_path) // Filtrar aquellos que tengan download_path definido
                                    .map((source) => ({
                                        value: source,
                                        text: `${source.service.name} ${(source.quality === null || source.quality === '' || source.quality === undefined) ? '' : source.quality === 0 ? 'CAM' : `${source.quality}p`}`,
                                        icon: source.service.icon // si tienes un icono para el servicio
                                    }))
                                }
                                ads={config_data.ads}
                            />
                        )
                    }
                    {report_status && (
                        <Report />
                    )
                    }
                </div>
            </div>
            {selectedOption && (
                <Embed option={selectedOption} title={video_data.title} cover={video_data.cover} ads={config_data.ads} />
            )}
        </div>
    );
}

export default Embeds;
import { useEffect, useState } from 'react';

const useEncryptedData = (url, shouldFetch) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(shouldFetch);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!shouldFetch) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, shouldFetch]);

    return { data, loading, error };
};

export default useEncryptedData;
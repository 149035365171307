import React, { useState } from 'react';

import '../assets/css/select.css';
import '../assets/css/download.css';
import icon from '../assets/img/download.svg';

function Download({ options, ads }) {
   
    const [clickedOption, setClickedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    // Filtrar los anuncios que tengan el tipo 'pop_under'
    const popUnderAds = ads.filter(ad => ad.type === 'pop_under');

    // Seleccionar uno de ellos aleatoriamente
    const getAdLink = () => {
        return popUnderAds.length > 0 ? popUnderAds[Math.floor(Math.random() * popUnderAds.length)].link : null;
    };

    const handleOptionClick = (option) => {
        if (clickedOption && clickedOption.value === option.value) {
            // Si la opción ya ha sido seleccionada antes, abrir el enlace en una nueva pestaña
            var download_url = '';
            //console.log(option)
            if (option.value.source === 'code') {
                //console.log()
                download_url = option.value.service.service_url + option.value.service.download_path.replace('FILE_CODE', option.value.embed);
            }
            
            window.open(download_url, '_blank');
        } else {
            // Mostrar publicidad si es la primera vez que se hace clic en la opción
            const ad_link = getAdLink();

            if (ad_link) {
                const popunder = window.open(ad_link, '_blank', 'width=800,height=600');

                if (popunder) {
                    // Intenta mover la ventana emergente a una posición detrás de la ventana principal
                    popunder.blur();
                    popunder.opener.window.focus();

                    // Forzar la ventana principal a traerla al frente (algunas veces necesario)
                    setTimeout(() => {
                        window.focus();
                    }, 100);
                } else {
                    console.log('La ventana emergente fue bloqueada.');
                }
            }

            setClickedOption(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="custom-select" style={{ minWidth: "25px" }}>
            <div className="download-button" onClick={toggleDropdown}>
                <img className='download-icon' src={icon} height={"20px"} alt="Download" />
            </div>
            {isOpen && (
                <div className="select-items" style={{ left: 'auto' }}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`select-item ${clickedOption?.value === option.value ? 'same-as-selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.icon && (
                                <img src={option.icon} alt={`${option.text} icon`} className="option-icon" />
                            )}
                            {option.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Download;
import React, { useState, useEffect } from 'react';
import '../assets/css/select.css';

function CustomSelect({ selectText, options, selectedOption, handleOptionChange, ads }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    options.find((option) => option.value === selectedOption) || null
  );

  // Filtrar los anuncios que tengan el tipo 'pop_under'
  const popUnderAds = ads.filter(ad => ad.type === 'pop_under');

  const handleOptionClick = (option) => {
    // Seleccionar uno de ellos aleatoriamente
    const ad_link = popUnderAds.length > 0 ? popUnderAds[Math.floor(Math.random() * popUnderAds.length)].link : null;

    if (ad_link) {
      const popunder = window.open(ad_link, '_blank', 'width=800,height=600');

      if (popunder) {
        // Intenta mover la ventana emergente a una posición detrás de la ventana principal
        popunder.blur();
        popunder.opener.window.focus();

        // Forzar la ventana principal a traerla al frente (algunas veces necesario)
        setTimeout(() => {
          window.focus();
        }, 100);
      } else {
        console.log('La ventana emergente fue bloqueada.');
      }
    }

    setSelected(option);
    setIsOpen(false);
    handleOptionChange(option); // Devuelve el objeto completo
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  if (selected === null) {
    return (<></>);
  } else {
    return (
      <div className="custom-select">
        <div className="select-selected" onClick={toggleDropdown}>
          <img className='selected-icon' src={selected.icon} height={"20px"} alt="" />
          {selectText}
        </div>
        {isOpen && (
          <div className="select-items">
            {options.map((option) => (
              <div
                key={option.value}
                className={`select-item ${selected?.value === option.value ? 'same-as-selected' : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                {option.icon && (
                  <img src={option.icon} alt={`${option.text} icon`} className="option-icon" />
                )}
                {option.text}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default CustomSelect;
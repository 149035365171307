import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useEncryptedData from '../hooks/useEncryptedData';
import { decodeEncryptedData } from '../utils/decodeEncryptedData';
import Loading from './Loading';
import Content from './Content';
import { api_url } from '../constants';
import { getAmbs } from '../utils/getAmbs';
import { createSecretKeys } from '../utils/createSecretKeys';

function ContentLoader() {

  const { tmdb_id, season, episode } = useParams();

  // Crea las llaves falsas para baitear
  createSecretKeys();

  /** Config */
  const [decodedConfigData, setDecodedConfigData] = useState(null);
  //const [shouldFetch, setShouldFetch] = useState(false);
  const api_config_url = `${api_url}/api/config`;
  const apiConfig = useEncryptedData(api_config_url, true);
  const ambs = getAmbs();

  /*
  useEffect(() => {
    const storedData = localStorage.getItem("decodedConfigData");
    const storedTimestamp = localStorage.getItem("decodedConfigDataTimestamp");
    const now = Date.now();

    // Si los datos existen y no han expirado, úsalos
    if (storedData && storedTimestamp && storedData !== 'undefined' ) {
      const age = now - parseInt(storedTimestamp, 10);
      console.log(storedData)
      if (age < 24 * 60 * 60 * 1000) {
        try {
          const parsedData = JSON.parse(storedData);
          setDecodedConfigData(parsedData);
        } catch (error) {
          console.error("Error parsing stored config data", error);
        }
        return;
      }
    }

    // Si los datos no existen o han expirado, marca para hacer la petición
    setShouldFetch(true);
  }, []);
  */

  useEffect(() => {
    const decryptConfigData = async () => {
      if (apiConfig.data) {
        try {
          const decodedConfigData = await decodeEncryptedData(apiConfig.data, ambs);
          console.log(decodedConfigData)
          setDecodedConfigData(decodedConfigData); // Aquí puedes usar los datos decodificados
        } catch (error) {
          console.error("Error decoding config data", error);
        }
      }
    };

    decryptConfigData();
  }, [apiConfig.data]);

  /** End Config */

  const [decodedVideoData, setDecodedVideoData] = useState(null);
  
  // Determina la URL según el tipo de contenido
  const api_video_url = season && episode
    ? `${api_url}/api/serie/${tmdb_id}/${season}/${episode}`
    : `${api_url}/api/movie/${tmdb_id}`;

  const apiVideo = useEncryptedData(api_video_url, true);

  useEffect(() => {
    const decryptVideoData = async () => {
      if (apiVideo.data) {
        try {
          const decodedVideoData = await decodeEncryptedData(apiVideo.data, ambs);
          setDecodedVideoData(decodedVideoData); // Aquí puedes usar los datos decodificados
        } catch (error) {
          console.error("Error decoding video data", error);
        }
      }
    };

    decryptVideoData();
  }, [apiVideo.data]);

  return (
    <div style={{ backgroundColor: "#111113", width: "100vw", height: "100vh" }}>
      {apiVideo.loading ? (
        <Loading />
      ) : decodedConfigData && decodedVideoData ? (
        <Content config_data={decodedConfigData} video_data={decodedVideoData}/>
      ) : (
        <Loading /> // O algún mensaje de error si no hay datos
      )}
    </div>
  );
}

export default ContentLoader;
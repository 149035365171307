import React, { useState, useRef, useEffect } from 'react';
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import "../assets/css/player.css";

function Player({ title, sources, poster, tracks, ads }) {
    const [popunderShown, setPopunderShown] = useState(false);
    const playerRef = useRef(null);

    // Filtrar solo los anuncios de tipo pop_under
    const popUnderAds = ads.filter(ad => ad.type === 'pop_under');

    // Seleccionar aleatoriamente un anuncio de tipo pop_under
    const [adLink, setAdLink] = useState(null);
    useEffect(() => {
        if (popUnderAds.length > 0) {
            const randomAd = popUnderAds[Math.floor(Math.random() * popUnderAds.length)];
            setAdLink(randomAd.link);
        }
    }, [popUnderAds]);

    const handlePlay = (e) => {
        if (!popunderShown && adLink) {
            // Mostrar popunder (abrir ventana)
            const popunder = window.open(adLink, '_blank', 'width=800,height=600');

            if (popunder) {
                e.preventDefault();
                // Intenta mover la ventana emergente a una posición detrás de la ventana principal
                popunder.blur();
                popunder.opener.window.focus();

                // Forzar la ventana principal a traerla al frente
                setTimeout(() => {
                    window.focus();
                }, 100);

                // Pausar el video
                playerRef.current.plyr.pause();

                // Marcar como mostrado el popunder
                setPopunderShown(true);
            } else {
                console.log('La ventana emergente fue bloqueada.');
            }
        }
    };

    const plyrProps = {
        source: {
            type: 'video',
            title,
            sources,
            poster,
            tracks,
        },
        options: {
            listeners: {
                play: handlePlay,
            },
        },
        ref: playerRef,
    };

    return (
        <div style={{ width: "100%", height: "99vh" }}>
            <Plyr {...plyrProps} />
        </div>
    );
}

export default Player;
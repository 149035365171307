export function getAmbs(){
  const now = new Date();

  // Fecha actual
  const day = String(now.getUTCDate()).padStart(2, '0');
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const year = now.getUTCFullYear();
  const currentDate = `${day}${month}${year}`; // Formato: ddMMyyyy

  // Fecha del día anterior
  const yesterday = new Date(now);
  yesterday.setUTCDate(now.getUTCDate() - 1);
  const yDay = String(yesterday.getUTCDate()).padStart(2, '0');
  const yMonth = String(yesterday.getUTCMonth() + 1).padStart(2, '0');
  const yYear = yesterday.getUTCFullYear();
  const previousDate = `${yDay}${yMonth}${yYear}`; // Formato: ddMMyyyy

  return [currentDate, previousDate];
};
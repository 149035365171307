export function createSecretKeys() {
    // Verificar si la clave ya está almacenada en localStorage
    let decryptKey = localStorage.getItem("decrypt_key");
  
    // Si no existe, generar una nueva clave y guardarla en localStorage
    if (!decryptKey) {
      decryptKey = generateRandomKey();
      localStorage.setItem("decrypt_key", decryptKey);
    }
  
    // Verificar si la cookie ya existe
    const aesPassword = getCookie("aes_password");
  
    // Si la cookie no existe, generar una nueva clave y crear la cookie
    if (!aesPassword) {
      const newAesPassword = generateRandomKey();
      document.cookie = `aes_password=${newAesPassword}; path=/; SameSite=Lax`;
    }
  }
  
  function generateRandomKey() {
    // Genera una cadena aleatoria de 16 caracteres hexadecimales
    return [...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
  }
  
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }
import React from 'react';
import Loading from './Loading';
import useGetMirrorTempLink from '../hooks/useGetMirrorTempLink';
import Player from './Player';

function Mirror({ share_id, title, quality, cover, ads }) {
    const { tempLink, loading, error } = useGetMirrorTempLink(share_id);

    if (loading) return <Loading/>;
    if (error) return <p>Error: {error}</p>;
    
    return (
      <div style={{ width: "100%", height: "99vh" }}>
        {tempLink ? (
          <Player title={title} sources = {[
            {
              src: tempLink,
              type: 'video/mp4',
              size: quality,
            }]}  poster = {cover} tracks = {[]} ads={ads} />
        ) : (
          <p>No link available.</p>
        )}
      </div>
    );
  }  

export default Mirror;